









import Vue from "vue";
export default Vue.extend({
  props: {
    value: String,
    quality: Number,
    scale: Number,
    width: Number,
    margin: Number,
    size: Number,
    whiteMargin: Boolean,
  },
  components: {
    VueQr: () => import(/* webpackPrefetch: true */ "vue-qr"),
  },
});
